<template>
  <div class="app-container">
    <div class="top" style="margin: 10px 0">
      <el-button type="success" icon="el-icon-plus" @click="showAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        :disabled="selectionList.length !== 1"
        icon="el-icon-edit-outline"
        @click="showEdit(selectionList[0])"
        >编辑</el-button
      >
      <!-- @click="" -->
      <el-button
        type="danger"
        :disabled="selectionList.length > 0 ? false : true"
        icon="el-icon-edit-outline"
        @click="handleDel"
        >删除</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      border
      default-expand-all
      @selection-change="handleSelectionChange"
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="marketNo" label="市场编号"></el-table-column>
      <el-table-column prop="marketName" label="市场名称"></el-table-column>
      <el-table-column prop="apiUrl" label="API地址"></el-table-column>
    </el-table>

    <el-dialog
      :title="handelType === 'new' ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="form" label-width="120px" :inline="true">
        <el-form-item label="市场名称">
          <el-input v-model="form.marketName" />
        </el-form-item>
        <el-form-item label="API地址">
          <el-input v-model="form.apiUrl" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="handelType === 'new'" type="primary" @click="handleAdd"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      listData: [],
      handelType: "",
      dialogVisible: false,
      selectionList: [],
      apiName:"/bigScreenMarket",
      form: {
        no: "",
        name: "",
        measure: "",
        location: "",
        sourceType: 0,
        sourceContent: "",
        picList: [],
        fileList: [],
      },
    };
  },
  created() {
    this.getDataList();
  },
  mounted() {
    // this.getArr();
  },
  methods: {

    async getDataList() {
      let result = await api.common.list({apiName:this.apiName});
      this.listData = result.data.data;
    },
    showEdit(obj) {
      this.handelType = "edit";
      this.form = obj;
      this.dialogVisible = true;
    },


    showAdd() {
      this.handelType = "new";
      this.form = {
        no: "",
        name: "",
        measure: "",
        location: "",
        sourceType: 0,
        sourceContent: "",
        fileList: [],
      };
      this.dialogVisible = true;
    },
    handleSelectionChange(e) {
      this.selectionList = e;
      console.log("e", this.selectionList);
    },

    handleEdit() {
      let { form,apiName } = this;
      api.common.update({apiName,...form}).then((res) => {
        if (res.code == 200) {
          this.$message({ message: "更新成功", type: "success" });
          this.getDataList();
          this.dialogVisible = false;
        }
      });
    },
    async handleAdd() {
      let { form } = this;
      let result = await api.common.create({apiName:this.apiName,...form});
      if (result.code == 200) {
        this.$message({ message: "新增成功", type: "success" });
        this.dialogVisible = false;
        this.getDataList();
      }
    },
    handleDel() {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // let arr = [];
        // arr.push(id);
        console.log("sedssd", this.selectionList);
        let ids = this.selectionList.map((x) => {
          return x.id;
        });
        api.remove({ ids }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
            this.getDataList();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.el-form-item{
  margin-right: 40px;
}
.el-form-item.content {
  display: block;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.upload_info {
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba($color: #000, $alpha: 0.2);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
// .avatar-uploader {
//   border: 1px dashed #d9d9d9;
// }
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.upload-box {
  display: flex;
}
.pic-list {
  display: flex;
  img {
    margin-right: 10px;
  }
  .list-box {
    position: relative;
  }
}

.el-upload-list__item-actions {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.el-upload-list__item-actions:after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.el-upload-list__item-actions span {
  display: none;
  cursor: pointer;
}

.el-upload-list__item-actions span + span {
  margin-left: 15px;
}

.el-upload-list__item-actions .el-upload-list__item-delete {
  position: static;
  font-size: inherit;
  color: inherit;
}

.el-upload-list__item-actions:hover {
  opacity: 1;
}

.el-upload-list__item-actions:hover span {
  display: inline-block;
}
</style>
