import { render, staticRenderFns } from "./bigScreenMarket.vue?vue&type=template&id=27ec619e&scoped=true&"
import script from "./bigScreenMarket.vue?vue&type=script&lang=js&"
export * from "./bigScreenMarket.vue?vue&type=script&lang=js&"
import style0 from "./bigScreenMarket.vue?vue&type=style&index=0&id=27ec619e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ec619e",
  null
  
)

export default component.exports